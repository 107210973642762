import axios from "axios";
let serverUrl = "";
// serverUrl = "http://localhost:3000";
serverUrl = `${process.env.GATSBY_SERVER_API_URL}`;

export const getPaymentIntent = async (
  orderId,
  totalPrice,
  chargeId,
  stripeId
) => {
  const intent = await axios.post(`${serverUrl}/api/stripe/intent`, {
    orderId,
    totalPrice,
    chargeId,
    stripeId
  });
  return intent;
};
export const getSetupIntent = async (customer) => {
  const intent = await axios.post(`${serverUrl}/api/stripe/set-up-intent`, {
    customer
  });
  return intent;
};

export const getPaymentMethodInfo = async (pm) => {
  const paymentMethod = await axios.get(
    `${serverUrl}/api/stripe/payment-method-info/${pm}`
  );
  return paymentMethod;
};

export const replaceCustomerPaymentMethod = async (chargeId, paymentMethod) => {
  const paymentMethodRes = await axios.post(
    `${serverUrl}/api/stripe/replace-payment-method`,
    { chargeId, paymentMethod }
  );
  return paymentMethodRes;
};

export const createStripeCustomer = (
  intent,
  values,
  lineItems,
  shopify_id,
  is_subscription,
  shipByDate,
  hasOnlyGCs
) => {
  return axios.post(`${serverUrl}/api/stripe/create-customer`, {
    intent,
    values,
    lineItems,
    shopify_id,
    is_subscription,
    shipByDate,
    hasOnlyGCs
  });
};

export const addOrderIdsToCustomer = (chargeId, orderId) => {
  return axios.post(`${serverUrl}/api/stripe/add-order-ids-to-customer`, {
    chargeId,
    orderId
  });
};
