import axios from "axios";
let serverUrl = "";
// serverUrl = "http://localhost:3000";
serverUrl = `${process.env.GATSBY_SERVER_API_URL}`;
export const createShopifyOrder = (
  values,
  customer,
  lineItems,
  shipping,
  discountApplications,
  shipByDate,
  deliveryDate,
  appliedGiftCards,
  giftNote,
  billingValues,
  giftCardTotal,
  totalPrice
) => {
  return axios.post(`${serverUrl}/api/checkout/create-order`, {
    values,
    lineItems,
    customer,
    shipping,
    discountApplications,
    shipByDate,
    deliveryDate,
    appliedGiftCards,
    giftNote,
    billingValues,
    giftCardTotal,
    totalPrice,
  });
};
export const calculateShopifyOrder = (
  values,
  customer,
  lineItems,
  shipping,
  discountApplications
) => {
  return axios.post(`${serverUrl}/api/checkout/calculate-order`, {
    values,
    lineItems,
    customer,
    shipping,
    discountApplications,
  });
};
export const createCustomer = (
  id,
  firstName,
  lastName,
  phone,
  acceptsMarketing,
  hasAccount
) => {
  return axios.post(`${serverUrl}/api/checkout/create-customer`, {
    id,
    firstName,
    lastName,
    phone,
    acceptsMarketing,
    hasAccount,
  });
};

export const getOrderName = (id) => {
  return axios.post(`${serverUrl}/api/stripe/get-order-name`, { id });
};

export const getOrderData = (id) => {
  return axios.post(`${serverUrl}/api/stripe/get-order-data`, { id });
};

export const addFulfillmentNumber = (
  prevOrderId,
  prevOrderNote,
  fulfillmentNumber
) => {
  return axios.post(`${serverUrl}/api/checkout/add-fulfillment-number`, {
    prevOrderId,
    prevOrderNote,
    fulfillmentNumber,
  });
};

export const completeShopifyOrder = (id) => {
  return axios.post(`${serverUrl}/api/checkout/complete-order`, { id });
};
export const createGiftcard = (email, amount) => {
  return axios.post(`${serverUrl}/api/giftcards/add`, {
    email,
    amount,
  });
};
export const applyGiftCard = (code) => {
  return axios.post(`${serverUrl}/api/giftcards/verify`, {
    code,
  });
};
export const redeemGiftcards = (
  appliedGiftCards,
  giftCardPrices,
  order_id,
  order_name
) => {
  console.log(appliedGiftCards, giftCardPrices);
  return axios.post(`${serverUrl}/api/giftcards/redeem`, {
    appliedGiftCards,
    giftCardPrices,
    order_id,
    order_name,
  });
};

export const sendDiscountCodeData = (code, gross_sales, discounts) => {
  return axios.get(
    `https://cml-embedded-gc-subscriptions.herokuapp.com/api/add-code?code=${code}&gross_sales=${gross_sales}&discounts=${discounts}`
  );
};
