import React, { useEffect, useState, Fragment } from 'react';
import Loader from 'react-loader-spinner';
import './create-order.scss';
import axios from 'axios';
import { Criteo } from '~/components/Criteo/Criteo';
import { createShopifyOrder, completeShopifyOrder } from '~/helpers/requests/checkout-requests';
import { getPaymentMethodInfo } from '~/helpers/requests/stripe-requests';
import {
  getUpsells,
  getShopifyProduct,
  submitUpsellPayment,
} from '~/helpers/requests/upsell-requests';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import {
  HeadingLarge,
  BodyTextAlt,
  BodyText,
  Heading,
  Subheading,
} from '~/components/.base/headings';
import { FlexBox, Row, Col } from '~/components/.base/containers';
import no_image from '~/images/collectionList/noimage.jpeg';
import { getShopifyImageTransformedSrc } from '../helpers';

const CreateOrderPage = props => {
  const [upsellProducts, setUpsellProducts] = useState([]);
  const [productsInOrder, setProductsInOrder] = useState([]);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [shipByDate, setShipByDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [stripeId, setStripeId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodInfo, setPaymentMethodInfo] = useState(null);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const shipBy = new Date(params.get('od'));
    console.log(shipBy);
    shipBy.setUTCHours(18);
    console.log(shipBy);
    shipBy.setDate(Number(params.get('od').split('-')[2]) - 1);
    console.log(Number(params.get('od').split('-')[2]) - 1, shipBy);
    setShipByDate(shipBy);
    const delivery = new Date(params.get('od'));
    console.log('DELIVER: ', delivery);
    delivery.setUTCHours(18);
    console.log('DELIVER: ', delivery);
    delivery.setDate(Number(params.get('od').split('-')[2]));
    console.log('DELIVER: ', Number(params.get('od').split('-')[2]), delivery);
    setDeliveryDate(delivery);
    setOrderId(params.get('oid'));
    setStripeId(params.get('sid'));
    setPaymentMethod(params.get('pm'));
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const upsellRes = await getUpsells('Email');
        console.log(upsellRes);
        let upsellProductsArr = [];
        for (let upsell of upsellRes.data) {
          const upsellData = await getShopifyProduct(upsell.upsell_handle);
          let price, compareAtPrice, savings;
          compareAtPrice = Number(
            upsellData.data.data.productByHandle.variants.edges[0].node.priceV2.amount
          );
          if (upsell.upsell_type === 'Percent Discount') {
            price = (compareAtPrice * (100 - Number(upsell.discount_amount))) / 100;
            savings = (compareAtPrice - price).toFixed(2);
          } else {
            price = compareAtPrice - Number(upsell.discount_amount);
            savings = Number(upsell.discount_amount);
          }
          upsellProductsArr.push({
            ...upsellData.data.data.productByHandle,
            upsell,
            hasAddedToCart: false,
            price,
            compareAtPrice,
            savings,
          });
        }
        setUpsellProducts(upsellProductsArr);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    if (orderId) {
      axios
        .get(
          // `http://localhost:3000/api/checkout/email-upsell-order?oid=${orderId}`
          `${process.env.GATSBY_SERVER_API_URL}/api/checkout/email-upsell-order?oid=${orderId}`
        )
        .then(res => {
          console.log(res);
          if (res.data.order && res.data.order.data.order) {
            const { email } = res.data.order.data.order;
            const { city, countryCodeV2, name, zip, provinceCode, address1, address2, phone } =
              res.data.order.data.order.shippingAddress;
            console.log('shippingAddress', {
              firstName: name.split(' ')[0],
              lastName: name.split(' ')[1],
              email,
              address1,
              address2,
              city,
              provinceCode,
              zip,
              countryCodeV2,
              phone,
            });
            setShippingAddress({
              firstName: name.split(' ')[0],
              lastName: name.split(' ')[1],
              email,
              address1,
              address2,
              city,
              provinceCode,
              zip,
              countryCodeV2,
              phone,
            });
          }
        });
    }
  }, [orderId]);
  useEffect(() => {
    if (paymentMethod) {
      getPaymentMethodInfo(paymentMethod)
        .then(res => {
          console.log(res);
          setPaymentMethodInfo(res.data);
        })
        .catch(err => console.log(err));
    }
  }, [paymentMethod]);
  /* const createOrder = () => {
  	axios
  		.post(
  			"http://localhost:3000/api/checkout/create-email-upsell-order",
  			{
  				products: upsellProducts
  					.filter((p) => p.hasAddedToCart)
  					.map((p) => ({
  						variant: { id: p.variants.edges[0].node.id },
  						quantity: 1,
  						customAttributes: [],
  						price: p.variants.edges[0].node.price,
  					})),
  				order: activeOrder,
  				customer_id: params.get("c"),
  				c_key: params.get("k"),
  			}
  		)
  		.then((res) => {
  			console.log(res);
  		});
  }; */

  const createShopifyOrderFn = async () => {
    console.log(productsInOrder);
    const totalSavings = productsInOrder.reduce((a, b) => {
      console.log(a, a.savings, Number(a.savings), Number(b.savings));
      return Number(a) + Number(b.savings);
    }, 0);
    const trueShipping = 0,
      lineItems = productsInOrder.map(p => ({
        variant: { id: p.variants.edges[0].node.id },
        quantity: 1,
        customAttributes: [
          {
            key: 'order_type',
            value: 'upsell',
          },
        ],
        price: p.variants.edges[0].node.price,
      })),
      discountApplications = [
        {
          value: {
            amount: `${totalSavings.toFixed(2)}`,
            title: 'Upsell Discount',
          },
          targetType: 'FIXED_AMOUNT',
        },
      ];
    console.log(discountApplications);
    let customer, lineItemsSubtotalPrice, subtotalPrice;
    const res = await createShopifyOrder(
      shippingAddress,
      customer,
      lineItems,
      trueShipping,
      discountApplications,
      `${shipByDate.getMonth() + 1}-${shipByDate.getDate()}-${shipByDate.getFullYear()}`,
      `${deliveryDate.getMonth() + 1}-${deliveryDate.getDate()}-${deliveryDate.getFullYear()}`
    );
    console.log('DRAFTORDERCREATE RES: ', res);
    return res.data.data.draftOrderCreate.draftOrder;
  };
  const createUpsellOrder = async () => {
    setIsLoading(true);
    try {
      const createdOrder = await createShopifyOrderFn();
      console.log('CREATED ORDER: ', createdOrder);
      const payment = await submitUpsellPayment(stripeId, createdOrder.totalPrice, createdOrder.id);
      console.log(payment);
      if (payment?.data?.error) {
        console.log('error: ', payment.data.error);
        throw new Error(payment.data.error.message);
        // Display error.message in your UI.
      } else {
        console.log('yay!!!');
        await completeShopifyOrder(createdOrder.id);
        setSuccessMessage(
          'Your products have been added to your order! They will arrive, along with the rest of your order, on your next scheduled delivery date.'
        );
        setIsLoading(false);
        if (window.fbq) {
          window.fbq('track', 'Purchase', {
            value: Number(createdOrder.totalPrice),
            currency: 'USD',
          });
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setPaymentErrorMessage(err.message);
      setTimeout(() => setPaymentErrorMessage(''), 10000);
    }
  };
  const handleAddToCart = i => {
    setUpsellProducts(prods =>
      prods.map((p, j) => ({
        ...p,
        hasAddedToCart: i === j ? true : p.hasAddedToCart,
      }))
    );
    setProductsInOrder(
      upsellProducts
        .map((p, j) => ({
          ...p,
          hasAddedToCart: i === j ? true : p.hasAddedToCart,
        }))
        .filter(prod => prod.hasAddedToCart)
    );
  };

  const handleRemoveFromCart = i => {
    setUpsellProducts(prods =>
      prods.map((p, j) => ({
        ...p,
        hasAddedToCart: i === j ? false : p.hasAddedToCart,
      }))
    );
    setProductsInOrder(
      upsellProducts
        .map((p, j) => ({
          ...p,
          hasAddedToCart: i === j ? false : p.hasAddedToCart,
        }))
        .filter(prod => prod.hasAddedToCart)
    );
  };
  return (
    <Row className="create-order-page">
      <Criteo />
      <Col className="products-container" size={5}>
        <HeadingLarge>
          Extra savings,
          <br />
          <span className="red"> no additional shipping</span>
        </HeadingLarge>
        <div className="grid" justify="space-between">
          {upsellProducts.length
            ? upsellProducts.map((p, i) => {
                const { price, compareAtPrice } = p;
                return (
                  <div key={i} className="upsell-thumb">
                    <div className="img-cont">
                      {p.images && p.images.edges.length ? (
                        <img
                          src={getShopifyImageTransformedSrc(
                            p.images.edges[0].node.originalSrc,
                            '_300x'
                          )}
                          alt=""
                        />
                      ) : (
                        <img src={no_image} alt="" />
                      )}
                    </div>
                    <FlexBox direction="column" align="center" className="details">
                      {p.hasAddedToCart ? (
                        <RedButton
                          md="0 auto 20px"
                          onClick={() => handleRemoveFromCart(i)}
                          className="added-btn"
                        >
                          <span className="reg-text">Added</span>
                          <span className="hover-text">Remove</span>
                        </RedButton>
                      ) : (
                        <WhiteButton md="0 auto 20px" onClick={() => handleAddToCart(i)}>
                          Add{' '}
                          <span
                            style={{
                              fontSize: '20px',
                              marginLeft: '.25rem',
                              marginRight: '-.25rem',
                            }}
                          >
                            +
                          </span>
                        </WhiteButton>
                      )}
                      {price !== compareAtPrice ? (
                        <div className="savings">
                          <Heading className="price">
                            <span class="sr-only">Current price:</span>
                            {`$${price.toFixed(2)} `}
                          </Heading>
                          <div>
                            <BodyTextAlt md="0 0 1px">
                              <span class="sr-only">Original price:</span>
                              <strike>{`$${compareAtPrice.toFixed(2)}`}</strike>
                            </BodyTextAlt>
                          </div>
                        </div>
                      ) : (
                        <Heading className="price">${p.variants.edges[0].node.price}</Heading>
                      )}
                      <BodyTextAlt>{p.title}</BodyTextAlt>
                    </FlexBox>
                  </div>
                );
              })
            : null}
        </div>
      </Col>
      <Col className="address-payment-info" size={2}>
        <div className="address">
          <Subheading>Shipping Address</Subheading>
          {shippingAddress ? (
            <FlexBox justify="space-between">
              <BodyText md="5px 0 37px">
                {shippingAddress.firstName} {shippingAddress.lastName}
                <br />
                {shippingAddress.address1}
                <br />
                {shippingAddress.address2 && shippingAddress.address2.length ? (
                  <Fragment>
                    {shippingAddress.address2}
                    <br />
                  </Fragment>
                ) : null}
                {shippingAddress.city}, {shippingAddress.provinceCode} {shippingAddress.zip}
                <br />
                {shippingAddress.country}
              </BodyText>
            </FlexBox>
          ) : null}
        </div>
        <div className="address">
          <Subheading>Payment Method</Subheading>
          {paymentMethodInfo ? (
            <FlexBox justify="space-between">
              <BodyText md="5px 0 37px">
                {paymentMethodInfo.card.brand[0].toUpperCase() +
                  paymentMethodInfo.card.brand.substring(1)}{' '}
                ending in {paymentMethodInfo.card.last4}
              </BodyText>
            </FlexBox>
          ) : null}
        </div>
        {paymentErrorMessage.length ? (
          <BodyText color="#d4212c">{paymentErrorMessage}</BodyText>
        ) : null}
        {successMessage.length ? <BodyText color="#d4212c">{successMessage}</BodyText> : null}
        <FlexBox justify="center">
          <RedButton
            className="confirm-btn"
            onClick={createUpsellOrder}
            disabled={
              !productsInOrder.length ||
              isLoading ||
              !shippingAddress ||
              !paymentMethodInfo ||
              successMessage.length
            }
          >
            {isLoading ? (
              <Fragment>
                <Loader type="TailSpin" color="#152622" height={16} width={16} />
                Adding...
              </Fragment>
            ) : (
              'Confirm Order'
            )}
          </RedButton>
        </FlexBox>
      </Col>
    </Row>
  );
};

export default CreateOrderPage;
